// https://nextjs.org/docs/app/building-your-application/styling/css-in-js#styled-components
'use client';

import { useServerInsertedHTML } from 'next/navigation';
import React, { FC, PropsWithChildren, useState } from 'react';
import { ServerStyleSheet, StyleSheetManager, ThemeProvider, createGlobalStyle } from 'styled-components';

import { light } from '@lichtblick/theme/constants/theme';
import { BaseStyles } from '@lichtblick/theme/utils/baseStyles';

const GlobalStyle = createGlobalStyle`
  ${BaseStyles}

  // Workaround for Safari's default system font on buttons.
  button {
    font: inherit;
    color: inherit;
  }
`;

export const StyledComponentsRegistry: FC<PropsWithChildren> = ({ children }) => {
  // Only create stylesheet once with lazy initial state
  // x-ref: https://reactjs.org/docs/hooks-reference.html#lazy-initial-state
  const [styledComponentsStyleSheet] = useState(() => new ServerStyleSheet());

  useServerInsertedHTML(() => {
    const styles = styledComponentsStyleSheet.getStyleElement();

    styledComponentsStyleSheet.instance.clearTag();

    return <>{styles}</>;
  });

  if (typeof window !== 'undefined') {
    return <>{children}</>;
  }

  return (
    <StyleSheetManager sheet={styledComponentsStyleSheet.instance}>
      <ThemeProvider theme={light}>
        <GlobalStyle />
        {children}
      </ThemeProvider>
    </StyleSheetManager>
  );
};
